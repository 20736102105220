<template>
   <div class="tokyo_tm_error">
        <div class="tokyo_tm_error_inner">
        <h1>404!</h1>
        <h3>Page not found</h3>
        <p>The page you were looking for could not be found.</p>
        <router-link class="ib-button" to="/">Go to Home</router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>